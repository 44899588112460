import React from "react";
import styled from "@emotion/styled";
import { Title, Subtitle, Paragraph } from "./Text";
import { ImageGallery } from "./Images";

const DetailsContainer = styled.div`
  text-align: center;
`;

const QuestionList = styled.dl`
  text-align: left;
`;

const Question = styled.dt`
  font-weight: bold;
  margin-top: 1rem;
  margin-bottom: 1rem;
  line-height: 1.5rem;
`;

function Details() {
  const weddingDate = "Saturday, September 20, 2025";
  const weddingVenue = "The Meadowlands at the DCA";
  const hotel = "Courtyard Norwalk";
  const galleryImages = [
    { src: "/images/dca-outdoors-01.jpg", alt: "DCA House Outdoors" },
    { src: "/images/dca-front.jpg", alt: "DCA House Front" },
    { src: "/images/dca-bird-sanctuary.jpg", alt: "DCA Bird Santuary" },
    { src: "/images/dca-indoors-02.jpg", alt: "DCA Indoors" },
    { src: "/images/dca-outdoors-02.jpg", alt: "DCA House Outdoors Again" },
    { src: "/images/dca-staircase.jpg", alt: "DCA Indoor Staircase" },
    { src: "/images/dca-history.jpg", alt: "DCA House History" },
  ];
  return (
    <>
      <DetailsContainer>
        <Title>Details</Title>
        <Subtitle>Venue</Subtitle>
        <ImageGallery images={galleryImages}></ImageGallery>
        <Paragraph>
          Our wedding will be at the beatiful Meadowlands at the DCA. The
          ceremony will take place in the lush outdoors garden area, with the
          reception to follow in their indoor reception hall.
        </Paragraph>
        <Subtitle>Q&A</Subtitle>
        <QuestionList>
          <Question>When is the wedding?</Question>
          <dd>Our wedding will be held on {weddingDate}!</dd>
          <Question>Where is the venue?</Question>
          <dd>
            The ceremony and reception will take place at {weddingVenue}. The address
            is:
            <br />
            <br />
            <strong>
              274 Middlesex Rd
              <br />
              Darien, CT 06820
            </strong>
            <br />
            <br />
            Visit their site for more information:{" "}
            <a href="https://dariendca.org/meadowlands/">
              Meadowlands at the DCA
            </a>
          </dd>
          <Question>Is there a hotel block for the wedding?</Question>
          <dd>
            We have a hotel block set up at {hotel} by Marriott. The address
            is:
            <br />
            <br />
            <strong>
              Courtyard Norwalk
              <br />
              474 Main Avenue
              <br />
              Norwalk, Connecticut, 06851
            </strong>
            <br />
            <br />
            The hotel is about a 15 minute drive from the venue.
            <br />
            <br />
            You can get a discounted rate by calling 1-800-647-7578 
            and requesting the Consolini/Motto Wedding Room Block.
            You can alternatively use this link to make a reservation: {" "}
            <a href="https://www.marriott.com/event-reservations/reservation-link.mi?id=1726857990052&key=GRP&guestreslink2=true">
              Group Rate for Consolini/Motto Wedding Room Block
            </a>
            <br />
            <br />
            The cut-off date for making a reservation is Friday, August 22, 2025.  
            Make sure to make reservations prior to this date! There is also a boat show that 
            weekend in Norwalk, so booking closer to the date may be tricky.
            <br />
            <br />
            For detailed instructions on travel from LGA, JFK, or HPN airports, you can: {" "}
            <a href="https://www.marriott.com/en-us/hotels/hpnnw-courtyard-norwalk/overview/"> visit this link here </a> 
            and scroll down to the "GETTING HERE" section towards the bottom of the page.    
          </dd>
          <Question>
            What is the dress code? 
          </Question>
          <dd>
            The dress will be cocktail attire!
          </dd>
          <Question>
            Can I wear sweatpants? 😃
          </Question>
          <dd>No. 😤</dd>
        </QuestionList>
        <br />
        <Paragraph>More details to be added soon!</Paragraph>
      </DetailsContainer>
    </>
  );
}

export default Details;
